<template>
    <div id="v-pills-letters" role="tabpanel" aria-labelledby="v-pills-letters-tab">
        <div class="col content">
          <div class="row">
            <div class="col-md-10"><h5 class="p-2">My interview call letters</h5></div>
            <div class="col-md-2"><span class="p-2" style="font-size: 14px;"><span style="color: #0054ad;text-decoration: none;cursor: pointer;"  class="text-decoration-none" >Need help?</span></span></div>
          </div>
        </div>
    </div>
</template>

<script>
import '../assets/hire.css';
export default {
    name: 'CallLetter'
}
</script>